<template>
  <div>
    <breadcrumb :items="breadcrumbs"></breadcrumb>
    <v-card
      color="rgba(0,0,0,0)"
      dark
      max-width="500"
      class="mx-auto py-0 mt-10"
    >
      <span class="text-h6 third--text">{{ $t("resellers.edit.title2", { reseller: this.reseller.name }) }}</span>
    </v-card>
    <v-card class="mx-auto mt-4" max-width="500" dark color="fifth">
      <v-divider></v-divider>
      <v-card-text class="px-5">
        <reseller-form :reseller="reseller"></reseller-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Breadcrumb from "../commons/breadcrumb.vue";
import ResellerForm from "./form.vue";

export default {
  created() {
    this.setBreadcrumbs();
  },
  props: {
    reseller: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    breadcrumbs: [],
  }),
  methods: {
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("resellers.title"),
          to: this.$i18n.getRouteLink("reseller"),
          disabled: false,
          exact: true,
        },
        {
          text: this.$t("resellers.edit.title"),
          disabled: true,
        },
      ];
    },
  },
  components: {
    Breadcrumb,
    ResellerForm,
  },
};
</script>